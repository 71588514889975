@import "../style/palette.module";
@import "../style/breakpoints.module";

$circleSize: 40px;

.languageSelector {
  position: relative;
  margin: 3px 14px;
  
  .languageSelectorDropdown {
    background-color: white;
    position: absolute;
    top: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;
    margin: 8px 0;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    color: $accent1-shade2;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;

    .optionIcon {
      width: 24px;
      height: 24px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 8px;
      
      @media (max-width: $wide-screen) {
        display: none
      }
    }
  }

  .option:hover {
    background-color: $accent1-tint1;
  }
}