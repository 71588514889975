@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.ourClass {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.ourClassInner {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 30px;
  padding: 80px;
  background-color: transparent;
  justify-content: center;
  align-items: center;

  @media (max-width: $wide-screen) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0px 0px 80px 0px;
  }
}
.ourClassInnerReverse {
  flex-direction: row-reverse;

  @media (max-width: $wide-screen) {
    flex-direction: column-reverse;
    text-align: center;
  }
}

.ourClassLeft {
  display: flex;
  padding: 60px;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: $wide-screen) {
    text-align: center;
  }
  
  .ourClassTitle {
    display: flex;
    flex-direction: column;
    font-size: 52px;
    color: $accent1-shade2;
    font-weight: 600;
    max-width: 1100px;
    line-height: 1.5;
    margin-bottom: 24px;
    z-index: 1;

    @media (max-width: $wide-screen) {
      font-size: 32px;
      line-height: 1;
    }
  }

  .ourClassSubtitle{
    color: $accent1-shade2;
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
  }

  .ourClassText {
    line-height: 1.5;
    max-width: 500px;
    color: $accent1-shade3;
    margin-bottom: 40px;

    @media (max-width: $wide-screen) {
      margin-bottom: 24px;
    }
  }
}

.ourClassImage {
  height: 500px;
  width: 500px;
  object-fit: contain;

  @media (max-width: $wide-screen) {
    width: 100%;
    height: 50%;
  }
}