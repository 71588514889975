@import "../style/palette.module";
@import "../style/breakpoints.module";

.footer {
  display: flex;
  flex-direction: row;
  background-color: $accent1-tint3;
  font-size: 14px;
  font-weight: 700;
  padding: 100px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $wide-screen) {
    font-size: 12px;
    padding: 42px 16px;
    flex-direction: column;
  }

  a {
    color: $accent1-shade2;
  }
  
  .footerColumn {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $wide-screen) {
      border-bottom: 2px solid rgb(166, 192, 229, 0.4);
      padding: 36px 0px;
    }

    .cerahLogo {
      padding: 9px 0px;
    }

    .footerElementRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .badgeIcons {
      margin-top: 20px;
    }

    .badgeIcon {
      height: 36px;
      margin-right: 16px;
      cursor: pointer;
    }

    .columnHeading {
      font-size: 16px;
      color: $accent1-shade3;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      line-height: 16px;
      font-weight: 800;
      padding: 18px 0px;
      margin-bottom: 18px;
    }

    .columnText {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 9px;
      margin-bottom: 9px;
      color: $accent1-shade3;
    }

    .logo {
      background: url('../../../img/logos/cerah.png') left no-repeat;
      background-size: contain;
      display: block;
      height: 32px;
      width: 32px;
    }

    .companyName {
      flex-grow: 1;
      color: $accent1-shade2;
      font-size: 16px;
      font-weight: 700;
      margin: 0 8px;
    }

    .socialMediaLogo {
      height: 36px;
      width: 36px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}