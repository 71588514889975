@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.ourMethod {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  background-color: $accent1-tint3;
  padding: 80px 0px;
  
  @media (max-width: $wide-screen) {
    padding: 40px 0px;
  }

  .ellipseLeft, .ellipseRight {
    position: absolute;
    z-index: 1;
  }

  .ellipseRight {
    right: 0;
    bottom: 0;
  }
}

.ourMethodTitle {
  text-align: center;
  width: 75%;
  max-width: 1100px;
  font-size: 38px;
  margin: 60px auto 30px auto;
  color: $accent1-shade2;
  font-weight: 600;
  z-index: 1;

  @media (max-width: $wide-screen) {
    margin: 60px auto;
    font-size: 30px;
    width: 90%;
  }
}

.sliderWrapper {
  z-index: 2;
  height: 80%;
  margin: 0px 48px;

  @media (max-width: $wide-screen) {
    margin: 0px;
  }
}

.swiper-button-prev .swiper-button-next {
  color: $accent1-shade3;
}

.ourMethodSlide {
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: $accent1-tint3;
  height: 90%;
  border-radius: 20px;
  box-shadow: 0px -20px 30px rgba(50, 50, 50, 0.05), 0px 20px 50px rgba(50, 50, 50, 0.2);
  margin: 48px;
  align-self: center;
  max-width: 1300px;

  @media (max-width: $wide-screen) {
    margin: 48px 24px;
    align-self: auto;
  }

  .doodle {
    width: 100%;
    height: 100%;
    padding: 80px 30px;
    border-radius: 20px;
    background: url("../../../../img/ourMethod/our-methods-doodle.webp");

    @media (max-width: $wide-screen) {
      padding: 40px 30px;
    }
  }
}

.methodsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  flex: 1;
}

.methodColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;

  @media (max-width: $wide-screen) {
    max-width: 100%;
  }
}

.methodTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $accent1-tint2;
  min-height: 160px;
  border-radius: 20px;
  padding: 40px 20px;
  margin-top: 44px;
  margin-bottom: 44px;
  flex: 1;

  @media (max-width: $wide-screen) {
    padding: 16px;
  }
}

.methodImage {
  max-height: 200px;
}

.methodText {
  font-size: 24px;
  font-weight: 700;
  color: $accent1-shade2;
  line-height: 33px;

  @media (max-width: $wide-screen) {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
}