@import "../style/palette.module";
@import "../style/breakpoints.module";

.button {
  background-color: $accent1-shade2;
  border-radius: 100px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;

  @media (max-width: $wide-screen) {
    font-size: 16px;
  }

  .arrow {
    display: inline;
    background-color: transparent;
    width: 0.7em;
    height: 0.4em;
    border-left: 0.7em solid;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    margin-left: 0.6em;
  }

  &:hover {
    opacity: 0.9;
  }

  &.clear {
    color: $accent1-shade2;
    background-color: $accent1-tint4;
    .arrow {
      border-left-color: $accent1-shade2;
    }
    &:hover {
      background-color: $accent1-tint2;
    }
  }

  &.disabled {
    cursor: unset;
    background-color: $accent1-tint5;

    &:hover {
      background-color: $accent1-tint5;
    }
  }

  &.small {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  &.large {
    padding: 20px 64px;

    @media (max-width: $wide-screen) {
      padding: 10px 40px;
    }
  }
}