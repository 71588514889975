@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.whyCerah {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  background-color: $accent1-tint3;
  padding: 80px 48px;
  height: 900px;
  max-height: 100vh;

  @media (max-width: $wide-screen) {
    padding: 24px 20px;
    height: 760px;
    max-height: calc(100vh + 24px);
  }

  .sliderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding-bottom: 40px;
    position: relative;
    flex: 1;
    min-height: 0;
  }
  
  .watermarks {
    position: absolute;
    top: -100px;
    right: 100px;
    opacity: 0.048;
    height: 100%;
    width: auto;
    overflow: hidden;
    
    @media (max-width: $wide-screen) {
      top: 0;
      right: 100px;
    }

    .watermark1 {
      position: absolute;
      top: 40px;
      right: 100px;
    }

    .watermark2 {
      position: absolute;
      top: -100px;
      right: -100px;
      transform: matrix(0.99, -0.09, 0.14, 1, 0, 0);
      z-index: -1;

      @media (max-width: $wide-screen) {
        top: 0;
        right: -100px;
      }
    }
  }
}

.whyCerahSlide {
  display: flex;
  flex-direction: row;
  flex: 1;
  z-index: 0;
  background-color: white;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  margin: 40px auto;
  height: calc(100% - 80px);
  max-width: 1200px;
  border-color: lightgray;
  justify-content: space-around;

  @media (max-width: $wide-screen) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: stretch;
    height: 100%;
    width: 100%;
    padding: unset;
    margin: 0 auto;
    overflow: hidden;
  }

  .whyCerahSlideImage {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 40px;
    height: 100%;

    @media (max-width: $wide-screen) {
      height: 50%;
      width: unset;
      margin: unset;
    }

    .whyCerahImg {
      height: 100%;
      
      @media (max-width: $wide-screen) {
        margin: unset;
      }
    }
  }

  .whyCerahSlideCaption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(50% - 80px);
    margin: 0 40px;

    @media (max-width: $wide-screen) {
      flex: 1;
      padding: 0 28px;
      margin: unset;
      width: unset;
    }

    .whyCerahTitle {
      color: black;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 24px;

      @media (max-width: $wide-screen) {
        font-size: 24px;
      }
    }

    .whyCerahText {
      color: $accent1-shade3;
      font-size: 24px;
      margin-bottom: 32px;

      @media (max-width: $wide-screen) {
        font-size: 16px;
        margin-bottom: 24px;
      }
    }
  }
}


