@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.blogArticlesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 64px;

  @media (max-width: $wide-screen) {
    padding: 32px 16px;
  }

  .subtitle {
    font-size: 24px;
    opacity: 0.4;
  }

  .articlesSlides {
    width: 100%;
    margin: 64px 0px;

    .articleContainer {
      display: flex;
      flex-direction: column;
      padding: 80px;
      border-radius: 16px;
      background-color: $accent1-tint3;
      max-width: 1100px;
      margin: 0px auto;

      @media (max-width: $wide-screen) {
        padding: 32px 24px;
      }

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width:  $wide-screen) {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .headerLeft {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          height: 350px;
          border-radius: 16px;

          @media (max-width: $wide-screen) {
            height: auto;
            width: 100%;
          }
        }

        .headerRight {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          margin: 0px 40px;

          @media (max-width: $wide-screen) {
            margin: 0px;
          }

          .title {
            font-size: 40px;
            font-weight: 700;
            color: $accent1-shade2;
            line-height: 1.4;
            text-align: center;

            @media (max-width: $wide-screen) {
              font-size: 20px;
              text-align: left;
              margin-top: 24px;
              margin-bottom: 12px;
            }
          }

          .writersName {
            font-size: 24px;
            color: $accent1-shade3;

            @media (max-width: $wide-screen) {
              font-size: 12px;
            }
          }

          .date {
            margin-top: 16px;
            font-size: 12px;

            @media (max-width: $wide-screen) {
              font-size: 10px;
            }
          }
        }
      }

      .content {
        margin-top: 48px;
        font-size: 22px;
        color: $accent1-shade3;
        text-align: justify;
        font-weight: 400;
        line-height: 1.6;

        @media (max-width: $wide-screen) {
          font-size: 14px;
          margin-top: 36px;
        }
      }

      .button {
        margin-top: 60px;

        @media (max-width: $wide-screen) {
          margin-top: 36px;
        }
      }
    }
  }

  .seeAllButton {
    color: $accent1-tint7;
    text-decoration: underline;
    cursor: pointer;
  }
}