@import "../style/palette.module";
@import "../style/variables.module";
@import "../style/breakpoints.module";

.cerahOnline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  .section {
    width: 100%;
  }

  .topBanner {
    height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, $accent1-tint6 45.61%, rgba(239, 245, 252, 0) 95.17%);
    position: relative;

    .ellipseLeft, .ellipseRight, .ellipseCenter {
      position: absolute;
      opacity: 1;
    }

    .ellipseLeft {
      top: 10%;
      left: 0;
      height: 80%;

      @media (max-width: 767px) {
        top: 0;
      }
    }

    .ellipseCenter {
      top: 15%;
      left: 50%;
      width: 12vw;
      
      @media (max-width: 767px) {
        left: unset;
        top: 0;
        right: 0;
      }
    }

    .ellipseRight {
      right: 0;
      bottom: 0;
      max-width: 30vw;
      max-height: 30%;
    }
  }

  .pageTitle {
    margin: 20px 20px 0;
    font-size: 40px;
    color: $accent1-shade2;
    font-weight: 700;
    text-align: center;

    strong {
      font-weight: 900;
    }
  }

  .pageSubtitle {
    font-weight: 400;
    font-size: 20px;
    color: $accent1-shade3;
    text-align: center;
    margin: 5px 20px 0;
    line-height: 1.4;

    strong {
      font-weight: 800;
    }
  }

  .studentImg {
    height: 300px;
    width: 100%;
    position: relative;
  }

  .perks {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .logo {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    margin-right: -8px;
  }
  
  .wizardContainer {
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .priceSection {
    height: 580px;
    width: 100%;
    padding: 10px;
    text-align: center;
    color: white;
    background-color: $accent1-shade2;
    margin-bottom: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      text-transform: capitalize;
      font-size: 30px;
      margin-bottom: 20px;

      strong {
        font-weight: 900;
      }
    }

    .groupSize {
      strong {
        font-weight: 800;
      }
    }

    .price {
      font-size: 44px;
      font-weight: 800;
      margin-bottom: 10px;
    }

    .duration {
      text-transform: uppercase;
      color: $accent1;
      font-weight: 800;
      font-size: 20px;
      margin-bottom: 10px;

      .durationFig {
        vertical-align: middle;
        font-size: 40px;
      }
    }

    .durationEquivalence {
      font-weight: 700;

      .equivalentTo {
        color: $accent1-shade1;
      }
    }

    .sessionDuration {
      font-weight: 700;
      margin-top: 30px;
      font-size: 28px;
    }

    .sessionsPerWeek {
      color: $accent1;
      font-weight: 800;
      font-size: 24px;
    }
  }

  .title {
    font-size: 34px;
    margin-bottom: 8px;
    font-weight: 900;
    text-align: center;
    justify-self: flex-start;
    padding: 0 10px;

    &.tryOut {
      color: $accent1-shade2;
    }

    &.free {
      color: $accent1-shade3;
    }
  }

  .arrow {
    margin: 5px auto;
  }

  .choiceTitle {
    font-size: 26px;
    font-weight: 900;
    margin: 20px;
    padding: 0 10px;
    color: $accent1-shade3;
    text-align: left;
    width: 75%;
    align-self: self-start;
    margin-left: 24px;
    min-width: 300px;
  }

  .choiceSubtitle {
    @extend .choiceTitle;
    font-size: 20px;
    color: $accent1-shade2;
    margin-top: -10px;
  }

  .wholeScreenSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    min-height: calc(100vh - 76px);
  }

  .halfScreenSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    min-height: calc(60vh - 76px);
  }

  .choiceSection {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    text-align: center;
    justify-content: center;
  }

  .courseChoice {
    background-color: $accent1-shade2;
    color: white;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &.hidden {
      visibility: hidden;
    }

    .header {
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      padding: 0 10px;
    }

    .choiceSpecs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 0;

      .chosenClassType {
        background-color: white;
        color: $accent1-shade2;
        font-weight: 800;
        border-radius: 10px;
        width: 120px;
        text-align: center;
        padding: 20px;
        margin: 0 20px;
      }

      .choiceText {
        font-weight: 700;
      }

      .choiceHighlight {
        color: $accent1-tint1;
        font-weight: 800;
      }
    }

    .promo {
      background-color: #f00;
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      padding: 10px 20px;
      margin: 20px 0;
      width: 100%;
    }
  }

  .priceChoice {
    padding: 0 20px;
    width: 100%;
    text-align: center;

    &.hidden {
      visibility: hidden;
    }

    .price {
      margin: 30px 0 40px;
      padding: 30px 10px;
      text-align: center;
      border-radius: 20px;
      background-color: $accent1-tint2;
      font-size: 36px;
      font-weight: 900;
      color: $accent1-shade3;

      .perMonth {
        color: $accent1-shade2;
        font-size: 20px;
        font-weight: 800;
      }

      .questionPanel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        overflow: hidden;
        cursor: pointer;

        .questionMark {
          background-color: $accent1-shade2;
          color: white;
          border-radius: 50px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
        }

        .bubbleArrow {
          color: $accent1-tint1;
          transform: rotate(90deg);
        }

        .bubble {
          background-color: $accent1-tint1;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
          font-size: 12px;
          color: $accent1-shade3;
          padding-right: 15px;
          height: 36px;
          display: flex;
          align-items: center;
        }
      }

      .conditions {
        height: 40px;
        margin-top: 20px;
        padding: 0 20px;
        font-size: 14px;
        color: $accent1-shade3;
        font-weight: 800;
        display: flex;
        align-items: center;
        text-align: left;
      }
    }
  }

  .buttonPanel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 10px auto;

    .light {
      font-weight: 400;
    }

    .highlighted {
      font-weight: 800;
    }
  }

  .testCallOuterWizardContainer {
    min-height: 90vh;
    width: 100%;
  }

  .testCallDescription {
    background-color: $accent1-tint2;
    color: $accent1-shade2;
    padding: 10px 24px;
    border-radius: 20px;
    margin: 10px 30px;
    text-align: center;
    font-size: 14px;
  }
}

.shortcutBtnPanel {
  width: 100%;
  margin: 10px auto;
}

.dontMissTheChance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $accent1-tint1;
  background: url("../../../img/cerahOnline/cerah-online-dont-miss-the-chance-backdrop.png") no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 100px 24px 100px 24px;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 24px;
  }

  .title {
    font-size: 52px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 36px;
    }
  }

  .subTitle {
    font-size: 40px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  .tryItNow {
    margin-top: 48px;

    @media (max-width: 767px) {
      margin-top: 36px;
    }
  }
}

.invisible {
  visibility: hidden;
}

.linkExpiredContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$navbar-height-wide-screen});
  justify-content: center;
  align-items: center;
  font-size: 24px;

  @media (max-width: $wide-screen) {
    height: calc(100vh - #{$navbar-height-small-screen});
    font-size: 18px;
  }

  .linkExpired {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $accent1-shade2;
    background-color: $accent1-tint1;
    padding: 24px;
    border-radius: 20px;
    max-width: 1200px;
    margin: 20px;

    .crossIcon {
      height: 24px;
      width: 24px;
      margin-right: 24px;
    }

  }
}