@import "../style/palette.module";
@import "../style/breakpoints.module";

.testimonialContainer {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgrey;
  align-items: center;
  text-align: center;
  height: 650px;
  width: 350px;
  position: relative;
  margin: 20px;
  overflow: hidden;

  @media (max-width: $wide-screen) {
    height: 600px;
  }
  
  .testimonialQuote {
    align-self: flex-start;
    margin: 24px;

    @media (max-width: $wide-screen) {
      margin: 18px;
    }
  }

  .testimonialText {
    height: 300px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 24px;
    color: $accent1-shade3;

    @media (max-width: $wide-screen) {
      height: 260px;
      font-size: 14px;
      margin: 12px 24px;
    }
  }

  .testimonialDisplayPictureContainer {
    position: absolute;
    bottom: 101px;
    left: 50%;
    transform: translate(-50%);
    height: 110px;
    width: 110px;
    border-radius: 200px;
    overflow: hidden;

    .displayPicture {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .personName {
    position: absolute;
    bottom: 54px;
    color: white;
  }

  .country {
    position: absolute;
    font-size: 13px;
    bottom: 30px;
    color: white;
  }

  .background {
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}