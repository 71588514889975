@import "../style/palette.module";
@import "../style/breakpoints.module";

$navbar-z-index: 1000;

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 48px 48px 16px 48px;
  z-index: $navbar-z-index;
  text-align: left;

  @media (max-width: $wide-screen) {
    background-color: white;
    padding: 16px 16px;
  }
  

  .hamIcon {
    display: none;
    @media (max-width: $wide-screen) {
      display: block;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  .logoContainer {
    height: 32px;
  }
  
  .stub {
    display: none;
    @media (max-width: $wide-screen) {
      flex: 1;
      display: unset;
    }
  }

  .navigationLinksOuterPadding {
    @media (max-width: $wide-screen) {
      max-width: 80%;
      transition: max-width 0.3s, max-height 0.3s;
      position: fixed;
      top: 0;
      right: 0;
      width: 80%;
      height: 100vh;
      z-index: 100;
      overflow: hidden;
      white-space: nowrap;
      box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.2);
      -webkit-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.2);
    }
    
    .navigationLinksContainer {
      :last-child {
        margin-right: 0;
      }

      @media (max-width: $wide-screen) {
        display: flex;
        flex-direction: column;
        box-sizing: content-box;
        height: 100%;
        padding: 42px 16px;
        background-color: white;
      }

      .closeNavButton {
        display: none;
        @media (max-width: $wide-screen) {
          display: unset;
          width: 16px;
          height: 16px;
          cursor: pointer;
          align-self: flex-end;
        }
      }

      .navigationLink {
        font-size: 16px;
        color: $accent1-shade3;
        margin: 0 24px;
        font-weight: 600;

        @media (max-width: $wide-screen) {
          margin-top: 28px;
          color: $accent1-shade2;
          margin-left: 10px;
        }
      }
    }
  }

  .navigationContainerHidden {
    @media (max-width: $wide-screen) {
      max-width: 0;
    }
  }
}