@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.appShowCaseContainer {
  display: flex;
  width: 100%;
  padding: 48px;
  background-color: $accent1-tint1;

  @media (max-width: $wide-screen) {
   padding: 24px; 
  }

  .innerContainer {
    background-color: $accent1-tint3;
    border-radius: 50px;
    width: 100%;
    position: relative;
    border: 1px solid lightgray;
    overflow: hidden;

    @media (max-width: $wide-screen) {
      border-radius: 16px;
      padding: 48px 18px;
    }

    .backdrop {
      position: absolute;
      bottom: -48px;
      left: -48px;
      z-index: 0;
      overflow: hidden;
      opacity: 0.4;
    }

    .contentWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      margin: 60px 0px;
      flex-wrap: wrap-reverse;

      .appContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        min-width: 30%;

        @media (max-width: $wide-screen) {
          text-align: center;
        }

        .title {
          font-size: 52px;
          color: $accent1-shade2;
          font-weight: 700;
          white-space: pre-line;
          margin-bottom: 60px;

          @media (max-width: $wide-screen) {
            font-size: 32px;
            margin-top: 60px;
          }
        }

        .subtitle {
          font-size: 24px;
          line-height: 1.5;
          color: $accent1-shade3;

          @media (max-width: $wide-screen) {
            font-size: 16px;
          }
        }

        .storeRow {
          display: flex;
          flex-direction: row;
          margin-top: 32px;

          @media (max-width: $wide-screen) {
            flex-direction: column;
          }

          .storeBadge {
            height: 60px;
            margin-right: 24px;
            cursor: pointer;

            @media (max-width: $wide-screen) {
              width: 200px;
              height: auto;
              align-self: center;
              margin-top: 24px;
              margin-right: 0;
            }
          }
        }
      }

      .appImage {
        border-radius: 50px;
        box-shadow: 8px 2px 20px 0px rgba(0,0,0,0.24);
        z-index: 1;
        max-width: 40%;

        @media (max-width: $wide-screen) {
          border-radius: 32px;
          max-width: 200px;
        }
      }
    }
  }
}