$main-color1: #fee773;
$main-color2: #ffa23e;
$dark-grey: #7D7C7C;
$light-grey: #BAC1C5;
$accent1: #a5c0e5;
$accent1-shade1: #A6C0E5;
$accent1-shade2: #455D89;
$accent1-shade3: #2D3037;
$accent1-tint1: #D7EFFF;
$accent1-tint2: #E6EFFC;
$accent1-tint3: #F2F7FD;
$accent1-tint4: #fff;
$accent1-tint5: #FAF9F6;
$accent1-tint6: #EFF5FC;
$accent1-tint7: #35ADFF;

:export {
  accent1: $accent1;
  accent1Shade1: $accent1-shade1;
  accent1Shade2: $accent1-shade2;
  accent1Shade3: $accent1-shade3;
  accent1Tint2: $accent1-tint1;
  accent1Tint1: $accent1-tint2;
}