@import "../style/palette.module";
@import "../style/breakpoints.module";
@import "../style/variables.module";

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.heroSection  {
  position: relative;
}

.arrow  {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: white;
}

.home {
  height: calc(100vh - #{$navbar-height-wide-screen});
  display: flex;
  align-items: flex-end;
  position: relative;
  flex-direction: row;

  @media (max-width: $wide-screen) {
    height: auto;
    min-height: calc(100vh - #{$navbar-height-small-screen});
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }

  .heroImg {
    width: 50%;
    height: 100%;
    align-items: flex-start;
    
    @media (max-width: $wide-screen) {
      width: 100%;
      height: calc((100vh - #{$navbar-height-small-screen}) / 2);
      align-items: center;
      flex-shrink: 0;
    }
  }

  .heroContent {
    display: flex;
    right: 0;
    width: 50%;
    max-width: 640px;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 80px;
    line-height: 1.5;

    @media (max-width: $wide-screen) {
      flex: 1;
      width: 100%;
      max-width: 500px;
      align-items: center;
      margin: 0;
      padding: 0 16px;
    }

    .textPane {
      display: flex;
      flex-direction: column;
      z-index: 1;
  
      @media (max-width: $wide-screen) {
        align-items: center;
        text-align: center;
      }
  
      .slogan1, .slogan2 .slogan3{
        max-width: 900px;
        letter-spacing: 0.02em;
        padding: 0;
        color: $accent1-shade2;
        margin-top: 15px;
  
        strong {
          font-weight: 700;
        }
        
        @media (max-width: $wide-screen) {
          margin: 15px auto;
        }
      }
  
      .slogan1 {
        font-size: 52px;
        font-weight: 700;
  
        @media (max-width: $wide-screen) {
          font-size: 32px;
        }
      }
  
      .slogan2 {
        font-size: 24px;
        font-weight: 500;
        color: $accent1-shade3;
        @media (max-width: $wide-screen) {
          font-size: 18px;
        }
      }

      .slogan3 {
        color: $accent1-shade3;
        margin-top: 38px;
        font-size: 18px;

        @media (max-width: 767px) {
          margin-top: 24px;
        }
      }
    }
  
    .buttonPane {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      margin-top: 80px;

      @media (max-width: 767px) {
        margin-top: 32px;
      }

      .underText {
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }
}

.title {
  text-align: center;
  width: 75%;
  max-width: 1100px;
  font-size: 52px;
  margin: 110px auto 30px auto;
  color: $accent1-shade2;
  font-weight: 600;
  z-index: 1;

  @media (max-width: $wide-screen) {
    margin: 60px auto;
    font-size: 30px;
    width: 90%;
  }
}

.heroSectionTitle {
  margin: 120px auto;
  font-size: 40px;

  @media (max-width: $wide-screen) {
    margin: 60px auto;
    font-size: 24px;
  }
}

.horizontalRule {
  border: 2px solid $accent1;
  opacity: 0.4;
  width: 60%;
}

.sectionTitle {
  font-size: 52px;
  font-weight: 700;
  margin: 30px auto;
  color: $accent1-shade2;
  text-align: center;
  padding: 0 20px;

  &.forDarkBgnd {
    color: white;
  }

  &.blackTitle {
    color: black;
  }

  @media (max-width: $wide-screen) {
    font-size: 32px;
  }
}

.sectionSubTitle {
  font-size: 40px;
  font-weight: 700;
  color: $accent1-shade2;
  line-height: 56px;
  text-align: center;

  @media (max-width: $wide-screen) {
    font-size: 20px;
    line-height: 1.5;
  }

  &.forDarkBgnd {
    color: white;
  }

  &.blackTitle {
    color: black;
  }

  &.textAlignLeft {
    text-align: left;

    @media (max-width: $wide-screen) {
      text-align: center;
    }
  }
}

.vision {
  background-color: white;
  max-width: 800px;
  padding: 40px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;

  .logo {
    background: white no-repeat center;
    background-size: contain;
    background-image: url('../../../img/logos/cerah.png');
    width: 100px;
    height: 100px;
    margin: 20px auto;
  }
}

.address {
  background-color: $accent1-shade2;
  color: white;
  font-size: 14px;
  margin: 0px 20px 40px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.ourClasses {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient($accent1-tint3, white);
  position: relative;

  .ellipse1 {
    position: absolute;
    top: 180px;
  }
}