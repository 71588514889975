@import "../style/palette.module";

.teacherEnquiry {
  background-color: $accent1;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  align-items: stretch;

  .closed {
    background-color: white;
    max-width: 600px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;

    .logo {
      background: url('../../../img/logos/cerah.png') left no-repeat;
      background-size: contain;
      display: block;
      height: 120px;
      width: 120px;
      margin-bottom: 40px;
    }

    .text {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
  }
}