@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.signUpNowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 48px;
  width: 100%;
  background: url("../../../../img/sign-up-now-banner-background.png") no-repeat;
  background-size: cover;

  @media (max-width: $wide-screen) {
    text-align: center;
    padding: 36px 24px;
  }

  .subtitle, .title {
    color: white;
    font-weight: 700;
  }
  .subtitle {
    font-size: 40px;
    line-height: 1.4em;

    @media (max-width: $wide-screen) {
      font-size: 20px;
    }
  }

  .title {
    font-size: 52px;
    text-transform: uppercase;
    line-height: 1.3em;

    @media (max-width: $wide-screen) {
      font-size: 32px;
    }
  }

  .button {
    margin-top: 24px;
    background-color: $accent1-tint2;
    color: $accent1-shade2;
  }
}