@import "../../style/palette.module";
@import "../../style/breakpoints.module";

.testimonialsSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 80px 0;

  .testimonialTitle {
    font-weight: 600;
    font-size: 52px;
    padding: 0 48px;
    
    @media (max-width: $wide-screen) {
      padding: 0 24px;
    }
  }

  .seeAllLink {
    color: $accent1-tint7;
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 24px;
  }
}