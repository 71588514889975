@import "../style/palette.module";
@import "../style/breakpoints.module";
@import "../style/variables.module";

.testimonialsPage {
  width: 100%;

  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$navbar-height-wide-screen});
    width: 100%;
    padding: 0 80px;
    background: linear-gradient(180deg, $accent1-tint6 45.61%, rgba(239, 245, 252, 0) 95.17%);

    @media (max-width: $wide-screen) {
      height: auto;
      justify-content: flex-start;
      padding: 0 24px;
      margin-bottom: 48px;
    }

    .ellipseLeft, .ellipseRight {
      position: absolute;
      opacity: 1;
    }

    .ellipseLeft {
      top: 10%;
      left: 0;
      height: 80%;
  
      @media (max-width: 767px) {
        top: 0;
      }
    }

    .ellipseRight {
      right: 0;
      top: 10%;
      max-width: 30vw;
      max-height: 30%;
    }

    .bannerRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      z-index: 1;
      margin-top: 32px;

      @media (max-width: $wide-screen) {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
      }

      .testimonialVideo {
        display: flex;
        justify-content: center;
        width: 50%;

        @media (max-width: $wide-screen) {
          align-self: center;
        }

        .landscapeVideo {
          display: none;
          @media (min-width: $wide-screen) {
            display: block;
            width: 100%;
          }
        }

        .portraitVideo {
          display: none;
          @media (max-width: $wide-screen) {
            display: block;
            max-height: 400px;
          }
        }
      }

      .bannerTestimonial {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        width: 50%;
        flex-shrink: 0;
        margin-left: 40px;
        padding: 40px 0 0 40px;
        position: relative;

        @media (max-width: $wide-screen) {
          display: flex;
          width: 100%;
          max-width: 100%;
          flex-direction: column;
          align-items:flex-start;
          margin: 24px 0 0 0px;
        }

        .quotes {
          position: absolute;
          top: 0;
          left: 0;
        }

        .text {
          font-size: 40px;
          font-weight: 600;
          color: $accent1-shade3;
          line-height: 56px;
          text-align: left;
          margin-top: 20px;

          @media (max-width: $wide-screen) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        .personName {
          font-size: 24px;
          margin-top: 24px;
          line-height: 20px;
        }

        .personInfo {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }

  .storiesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;

    @media (max-width: $wide-screen) {
      padding: 0px;
    }
  }
}

.testimonialsGridAligner {
  max-width: 478px;
  width: 100%;

  @media (min-width: 924px) { // ((350 + 40) * 2) + 96 + 48
    max-width: 924px
  }

  @media (min-width: 1314px) { // ((350 + 40) * 3) + 96 + 48
    max-width: 1314px;
  }
}

.testimonialSlide {
  display: flex;
  justify-content: center;
}