.downloadSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 140px;
  margin: 30px 0 60px;
}

.subtitle {
  font-size: 20px;
  margin-top: 20px;
  padding: 20px;
}

.appleStoreBadge {
  background-image: url('../../../../img/logos/app-store-badge.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 56px;
  width: 168px;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.googlePlayBadge {
  background-image: url('../../../../img/logos/google-play-badge.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 56px;
  width: 188px;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
}

.textContent {
  margin-top: 40px;
  padding: 20px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
